:root {

  /* colors: default dark moede */
  --primary-color: #03418a;
  --primary-color-variant: #102C4D;
  --secondary-color: #F27300;
  --secondary-color-variant: #F24100;
  --background-color: #1e1e1e;
  --surface-color: #222423;
  --on-background: #fefefe;
  --on-surface: #fefefe;
  --on-primary: #fefefe; /* Accent or Secondary*/
  --on-accent: #1e1e1e;

  

  --dark-color: #1e1e1e;
  --white-color: #fefefe;

  /* shadows */
  --mid-shadow: 0 2px 5px 0 rgba(0, 0, 0, .25), 0 3px 10px 5px rgba(0, 0, 0, 0.05);


  /* dimensions  */
  --top-bar-height: 80px;
  --bottom-bar-width: 300px;
  --banner-height: 400px;
  --content-feed-height: 300px;

  /* indices */
  --low-index: 1;
  --mid-index: 10;
  --high-index: 20;
  --ultra-high-index: 30;
  --super-ultra-high-index: 999999999999999;
}

[data-theme='dark'] {
  /* Dark theme colors */
  --primary-color: #03418a;
  --primary-color-variant: #102C4D;
  --secondary-color: #F27300;
  --secondary-color-variant: #F24100;
  --background-color: #1e1e1e;
  --surface-color: #222423;
  --on-background: #fefefe;
  --on-surface: #fefefe;
  --on-primary: #fefefe; /* Accent or Secondary*/
  --on-accent: #1e1e1e;
}

[data-theme='light'] {
  /* Light theme colors */
  --primary-color: #03418a;
  --primary-color-variant: #102C4D;
  --secondary-color: #F27300;
  --secondary-color-variant: #F24100;
  --background-color: #E7E8E6;
  --surface-color: #d5d5d5;
  --on-background: #1e1e1e;
  --on-surface: #1e1e1e;
  --on-primary: #fefefe;
  --on-accent: #1e1e1e;
}

/* General Media Queries */
.mView { /* shows only on mobile */
  display: none;
}

.dView { /* shows only on desktop */
  display: block;
}

.bView { /* shows both on mobile and desktop */
  display: block;
}



@media only screen and (max-width: 768px) {
  :root {
    /* dimensions  */
    --banner-height: 350px;
    --content-feed-height: 200px;
  }
  .mView { /* shows only on mobile */
    display: block;
  }

  .dView { /* shows only on desktop */
    display: none;
  }
}

/*---------------- Fonts -------------------*/
@font-face {
  font-family: "FontRegular";
  src: local("FontRegular"), url("./fonts/Poppins/Regular.ttf") format("truetype");
}

@font-face {
  font-family: "FontBold";
  src: local("FontBold"), url("./fonts/Poppins/Bold.ttf") format("truetype");
}

@font-face {
  font-family: "FontLight";
  src: local("FontLight"), url("./fonts/Poppins/Light.ttf") format("truetype");
}

@font-face {
  font-family: "FontSemiBold";
  src: local("FontSemiBold"), url("./fonts/Poppins/SemiBold.ttf") format("truetype");
}


.font-bold{
  font-family: "FontBold" !important;
}

.font-regular{
  font-family: "FontRegular" !important;
}

.font-light{
  font-family: "FontLight" !important;
}

.font-semi-bold{
  font-family: "FontSemiBold" !important;
}

/*--------------- font end here -----------------*/


/* General Styling */
body,
html {
  width: 100%;
  margin: 0;
  padding: 0;
  background: var(--background-color);
  color: var(--on-background);
  font-family: "FontRegular";
  font-size: 1em;
}

#root {
  width: 100%;
  margin: 0;
  padding: 0;
}


/* WIDGETS */
* {
  /* transition: all .2s ease; */
}
/* FullPane */
.FullPane {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--low-index);
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: auto;
  background: var(--background-color);
  padding-bottom: calc(1 * var(--top-bar-height));
}


.FullPane[data-support-top-bar='true'] {
  height: calc(100vh - var(--top-bar-height));
  top: var(--top-bar-height);
}

.FullPane[data-support-top-bar='false'] {
  height: 100vh;
  top: 0;
}


.TabPills {
  width: 100%;
  height: calc(0.6 * var(--top-bar-height));
  position: fixed;
  top: var(--top-bar-height);
  left: 0;
  border-bottom: 2px solid var(--primary-color);
  right: 0;
  z-index: var(--mid-index);
  margin: 0;
  padding: 0;
  overflow-x: auto;
  overflow-y: hidden;
  background: var(--background-color);
  padding: 10px;
  font-family: "FontSemiBold" !important;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.TabPills::-webkit-scrollbar,
.TabPills > ul::-webkit-scrollbar {
  display: none;
}

.TabPills > ul {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.TabPills
li {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}
.TabPills
li >
button  {
  font-family: "FontSemiBold" !important;
  color: var(--on-background);
  width: 100%;
  border-bottom: 2px solid var(--primary-color) !important;
  border-radius: 0px !important;
}

.TabPills
li >
button[aria-selected="true"] {
  font-family: "FontSemiBold" !important;
  color: var(--on-primary) !important;
  background: var(--primary-color) !important;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}


.z-depth-1,
.z-depth-1-half {
  box-shadow: var(--mid-shadow);
}

.text-primary {
  color: var(--primary-color) !important;
}

.bg-primary {
  background: var(--primary-color) !important;
  color: var(--on-primary) !important;
}

.bg-surface {
  background: var(--surface-color) !important;
  color: var(--on-surface) !important;
}

.btn {
  box-shadow: none;
  outline: none;
}

.btn:focus {
  box-shadow: none;
  outline: none;
}

.btn-outline-light {
  border: 2px solid var(--on-background) !important;
  color: var(--on-background);
}

.text-secondary {
  color: var(--secondary-color) !important;
}

.bg-secondary,
.bg-secondary:hover {
  background: var(--secondary-color) !important;
  color: var(--on-accent) !important;
}

.text-dark {
  color: var(--dark-color) !important;
}

.mDescriptionHolder {
  overflow-x: auto !important;
  white-space: pre-wrap !important;
  white-space: -moz-pre-wrap !important;
  white-space: -pre-wrap !important;
  white-space: -o-pre-wrap !important;
  word-wrap: break-word !important;
}

.bg-dark {
  background: var(--dark-color) !important;
}

.text-white {
  color: var(--white-color) !important;
}

.bg-white {
  background: var(--white-color) !important;
}


.mSpacerMargin {
  margin-top: calc(0.5 * var(--top-bar-height));
  margin-bottom: calc(0.5 * var(--top-bar-height));
}



.form-group {
  width: 100%;
  margin-bottom: 10px;
}

.btn-circle-sm {
  height: 70px;
  width: 70px;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 35px;
  font-size: 12px;
  margin: 0;
}

.btn-circle-lg {
  height: 100px;
  width: 100px;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 50px;
  font-size: 14px;
  margin: 0;
}

.react-pdf__Page__textContent,
.react-pdf__Page__annotations {
  display: none;
}

button[data-disabled="true"] {
  opacity: 0.5 !important;
}

button:hover {
  font-family: "FontSemiBold";
}
.noMargin {
  margin: 0 !important;
}

.noPad {
  padding: 0 !important;
}

.btn-rounded {
  border-radius: 20px;
}

.no-shadow {
  box-shadow: none !important;
}

.support-loading {
  min-width: 50px;
  min-height: 50px;
  position: relative;
}

.mNoScrollbars::-webkit-scrollbar {
  display: none;
}

.mNoScrollbars {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.mHorizontalTab {
  padding: 10px;
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  height: calc( 1.3 * var(--top-bar-height));
  margin-bottom: 10px;
}

.card,
.card-body,
.modal-body,
.modal-content {
  background: var(--surface-color) !important;
  color: var(--on-surface) !important;
}

#viewerModal
.modal-body {
  background: var(--background-color) !important;
  color: var(--on-surface) !important;
}

#viewerModal
.modal-content {
  background: var(--background-color) !important;
  color: var(--on-background) !important;
}

label {
  font-size: 14px;
  color: var(--on-background);
  font-family: "FontLight";
}


input:not([type='radio']),
textarea {
  background: none !important;
  border-radius: 0 !important;
  border: none !important;
  color: var(--on-background) !important;
  box-shadow: none !important;
  border-bottom: 1px solid var(--secondary-color) !important;
}

input:not([type='radio']):focus,
textarea:focus {
  background: none !important;
  box-shadow: none !important;
  outline: none !important;
  color: var(--on-background) !important;
  border-bottom: 3px solid var(--secondary-color) !important;
}

select {
  background: none !important;
  border-radius: 0 !important;
  border: none !important;
  color: var(--primary-color) !important;
  box-shadow: none !important;
  border-bottom: 1px solid var(--secondary-color) !important;
}

.text-muted {
  font-family: "FontRegular" !important;
}

table {
  color: var(--on-background) !important;
}

select:focus {
  background: none !important;
  box-shadow: none !important;
  outline: none !important;
  color: var(--primary-color) !important;
  border-bottom: 3px solid var(--secondary-color) !important;
}


/* TinyMCE editor */
body.mceContentBody { 
  background: var(--background-color); 
  color: var(--on-background);
}

body.mceEditorBody { 
  background: var(--background-color); 
  color: var(--on-background);
}

pre {
  background: var(--surface-color);
  color: var(--on-surface);
  padding: 10px;
}

.tox
.tox-textfield {
  color: black !important;
}

.tox
.tox-textfield:focus {
  color: black !important;
}

.tox
.tox-textarea {
  color: black !important;
}

.tox
.tox-textarea:focus {
  color: black !important;
}

/* modal */
.modal-content {
  background: var(--white-color);
}

.modal-body {
  font-size: 14px;
}

/* card */
.card,
.card-body {
  border-radius: 20px;
}

/* Loader */
.Loader {
  width: 40px;
  height: 40px;
  position: absolute;
  border-radius: 20px;
  background: none;
  border: 4px solid var(--secondary-color) !important;
  border-left: 4px solid var(--background-color) !important;
  top: 50%;
  z-index: inherit;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
  animation-name: "loading";
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  -webkit-animation-name: "loading";
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



/* TopBar */
.TopBar {
  height: var(--top-bar-height);
  width: 100%;
  background: var(--background-color);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--high-index);
  padding-left: 10px;
  padding-right: 15px;
  color: var(--secondary-color);
}

.TopBar[data-hidetopbar="true"] {
  display: none;
}

.TopBar
.mTitle {
  font-size: 30px;
  font-family: "FontRegular";
}

/* BottomBar */
.BottomBar {
  height: var(--top-bar-height);
  width: var(--bottom-bar-width);
  background: var(--surface-color);
  box-shadow: var(--mid-shadow);
  position: fixed;
  bottom: 20px;
  left: 50%;
  z-index: var(--high-index);
  padding: 5px;
  transform: translateX(-50%);
  border-radius: 30px;
  overflow: hidden;
}


.BottomBar >
.mNavItem {
  width: 80px;
  color: var(--primary-color);
  cursor: pointer;
  transition: all .1s ease-in;
  height: 100%;
  border-radius: 30px;
}

.BottomBar >
.mNavItem[data-selected="true"] {
  color: var(--secondary-color);
  background: var(--primary-color);
}

.BottomBar >
.mNavItem >
.mNavIcon {
  font-size: 30px;
  margin: 0px;
  color: inherit;
}

.BottomBar >
.mNavItem >
.mNavTitle {
  font-size: 12px;
  margin: 0px;
  font-family: "FontLight";
  color: inherit;
}

/* CategoriesFixed */
.CategoriesFixed {
  height: var(--top-bar-height);
  width: 100%;
  background: var(--background-color);
  position: fixed;
  top: var(--top-bar-height);
  left: 0;
  right: 0;
  z-index: var(--high-index);
  padding: 0px;
  color: var(--secondary-color);
}


/* MainLoader */
.MainLoader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: var(--super-ultra-high-index);
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
}


/* MainBody */
.MainBody {
  width: 100%;
  margin: 0;
  padding: 0;
  margin-top: var(--top-bar-height);
  padding-top: calc(0.3 * var(--top-bar-height));
  padding-bottom: calc(var(--top-bar-height) + 30px);
}

.MainBody[data-hidetopbar="true"] {
  margin-top: 0;
  padding-top: 0px;
}
/* banner */
.Banner {
  width: 100%;
  height: var(--banner-height);
  background: var(--dark-color);
  border-radius: 20px;
  overflow: hidden;
  color: var(--white-color);
  padding: 0;
  position: relative;
  margin: 0;
  margin-top: var(--top-bar-height);
}

.Banner[data-is-search='true'] {
  margin-top: 0px;
}

.Banner 
.mSlide {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-position: left;
  background-size: auto 100%;
  background-repeat: no-repeat;
}


.Banner 
.mSlide > 
img {
  width: 100%;
  height: 100%;
}

.Banner 
.mSlide >
.mInfo {
  position: absolute;
  right: 0;
  top: 0;
  width: 45%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.Banner 
.mSlide >
.mInfo >
div {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  padding: 10px;
  margin: 0;
  transform: translateY(-50%);
  word-wrap: break-word;
}

.Banner 
.mSlide >
.mInfo >
div >
* {
  position: relative;
}

/* Categories */
.Categories {
  overflow-y: hidden;
  overflow-x: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.Categories::-webkit-scrollbar {
  display: none;
}

.Categories >
.mItem {
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 5px;
  text-transform: capitalize;
  margin-right: 5px;
  font-family: "FontSemiBold";
  font-size: 12px;
  border-top-left-radius: 30px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 10px;
  border: 1px solid var(--secondary-color);
  color: var(--on-background);
  outline: none;
}

.Categories >
.mItem[data-current="true"] {
  background: var(--secondary-color);
  color: var(--dark-color);
}

.Categories[data-fixed="false"]
.mItem {
  background: none;
  color: var(--primary-color);
  border: none;
  margin: 0;
  border-radius: 10px;
  font-size: 16px;
}

.Categories[data-fixed="false"] >
.mItem[data-current="true"] {
  background: var(--primary-color);
  color: var(--white-color);
  
}

.ContentFeed {
  width: 100%;
  height: calc(var(--content-feed-height) * 1.2);
  position: relative;
  overflow: hidden;
  cursor: pointer;
  
}

.ContentFeed[data-type="book"] {
  height: calc(var(--content-feed-height) * 1.4);
}

.ContentFeed[data-type="podcast"] {
  height: calc(var(--content-feed-height) * 1.4);
}

.ContentFeed >
.mImg {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background-image: url(./assets/logo.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.ContentFeed >
.mImg >
img {
  width: 100%;
  height: 100%;
  z-index: inherit;
  object-fit: cover;
}

.ContentFeed >
.mOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1))
}

.ContentFeed >
.mBody {
  z-index: 3;
  padding: 5px;
  width: 100%;
  bottom: 0;
  position: absolute;
}

.ContentFeed >
.mBody>
.mTitle {
  font-size: 16px;
  line-height: 18px;
  margin: 0px;
  padding: 10px;
  width: 100%;
  max-height: 144px;
  overflow: hidden;
  margin-bottom: 10px;
  font-family: "FontRegular";
  color: var(--white-color);
}

.ContentFeed >
.mBody>
.mCat {
  font-size: 12px;
  line-height: 12px;
  margin: 0;
  margin-bottom: 10px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "FontBold";
  color: var(--secondary-color);
}

.ContentFeed >
.mBody>
.mPrice {
  font-size: 15px;
  line-height: 15px;
  margin: 0;
  margin-left: 10px;
  margin-bottom: 10px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--secondary-color);
  white-space: nowrap;
  font-family: "FontSemiBold";
}


/* mSectionHeader */
.mSectionHeader {
  width: 100%;
  min-height: calc(0.5 * var(--top-bar-height));
  overflow: hidden;
  margin-bottom: 20px;
  margin-top: 20px;
}

.mSectionHeader >
.mTitle {
  flex-shrink: 0;
  flex-grow: 1;
  font-size: 18px;
  font-family: "FontBold";
  color: var(--on-background);
}

.mSectionHeader >
.mAction {
  flex-shrink: 0;
  font-size: 14px;
  font-family: "FontSemiBold";
  cursor: pointer;
}

/* ContentInfo */
.ContentInfo {
  width: 100%;
  min-height: 30vh;
  background: var(--background-color);
}

.ContentInfo >
.mBanner {
  width: 100%;
  height: auto;
  padding: 0px;
  margin: 0px;
  position: relative;
  background: none;
  overflow: hidden;
}

.ContentInfo >
.mBanner >
img {
  width: 100%;
  height: auto;
}

.ContentInfo >
.mBanner >
.mCover {
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 1));
  position: absolute;
}

.ContentInfo >
.mBanner >
.mCover >
.mTitle {
  position: absolute;
  width: 100%;
  bottom: calc(10px + var(--top-bar-height));
  padding: 10px;
  font-family: "FontSemiBold";
  word-wrap: break-word;
  font-size: 30px;
  color: var(--white-color);
}

.ContentInfo >
.mInfo {
  width: 100%;
  height: auto;
  min-height: 40vh;
  padding: 0px;
  padding-bottom: 20px;
  margin: 0px;
  position: relative;
  box-shadow: var(--mid-shadow);
  background: var(--white-color);
  color: var(--dark-color);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  margin-top: calc( -1 * var(--top-bar-height));
  overflow: hidden;
}

.ContentInfo >
.mInfo >
img {
  width: 100%;
  height: auto;

}

.ContentInfo >
.mInfo
.mBtn {
  border-radius: 20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--white-color);
  font-size: 15px;
  font-family: "FontLight";
  margin: 5px;
}

#_pageReader {
  width: 100%;
  padding: 0;
  padding-bottom: var(--top-bar-height);
  position: absolute;
  z-index: inherit;
  overflow: auto;
  left: 50%;
  transform: translateX(-50%);
}

.BookControls {
  width: 100%;
  height: var(--top-bar-height);
  z-index: var(--super-ultra-high-index);
  background: var(--surface-color);
  box-shadow: var(--mid-shadow);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  margin:0;
  padding: 0;
  overflow: hidden;
}

.BookControls >
.container {
  height: 100%;
  padding: 0;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
}

.CoverPhotoUploader {
  width: 100%;
  height: 40vh;
  cursor: pointer;
  overflow: hidden;
}

.CoverPhotoUploader >
img {
  width: auto;
  height: 100%;
}

.mp3Btn,
.mp3BtnLg {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: none;
  font-size: 30px;
  padding: 0 !important;
  background: none;
  align-self: center;
  color: var(--primary-color);
}

.mp3BtnLg {
  width: 80px !important;
  height: 80px !important;
  color: var(--secondary-color);
  font-size: 60px;
}

.mp3Track {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 5px;
  background: none;
  position: relative;
}

.mp3Track >
.mProgress {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  background: var(--secondary-color) !important;
  border: none !important;
  cursor: pointer;
}

.mp3Track >
.mProgress:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.mp3Track >
.mProgress::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 15px; /* Set a specific slider handle width */
  height: 15px; /* Slider handle height */
  background: var(--primary-color); /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.mp3Track >
.mProgress::-moz-range-thumb {
  width: 15px; /* Set a specific slider handle width */
  height: 15px; /* Slider handle height */
  background: var(--primary-color); /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.mStarsBar {
  margin-top: 10px;
}

.mStarsBar >
.mStar {
  align-self: center;
}

.mStarsBar >
.mStar >
.mStarIcon {
  font-size: 40px;
  color: var(--secondary-color);
  cursor: pointer;
}

/* CertView */
.CertView {
  /*width: 900px;
  height: 600px;*/
  width: 297mm;
  height: 210mm;
  margin: auto;
  margin-left: auto;
  margin-right: auto;
  background-image: url('./assets/cert_bg.png');
  background-size: cover;
  background-attachment: scroll;
  background-position: center;
  background-repeat: no-repeat;
  padding: 30px;

  position: relative;
}

@media print {
  .CertView {
    width: 297mm;
    height: 210mm;
  }
}

.CertView .mLogo {
  width: 190px;
  height: 200px;
  position: absolute;
  top: 50px;
  left: 60px;
}

.CertView .mSign {
  width: 100px;
  opacity: 0.7;
}
